import { Component, AfterViewInit, ElementRef,AfterViewChecked, OnInit } from '@angular/core';
import * as amc from '@amc-technology/davinci-api'
import { LoggerService } from '../services/logger-global-feature.service';
import { ERROR_CODE } from '@amc-technology/davinci-api';

@Component({
  selector: 'app-home-global-feature',
  templateUrl: './home-global-feature.component.html',
  styleUrls: ['./home-global-feature.component.scss']
})
export class HomeComponent implements AfterViewInit, OnInit{
  private minHeight = 30;
  private mindWidth = 30;
  private maxHeight = 84;
  private maxWidth = 80;
  enabled: boolean;

  menuShow = false;
  set showMenu(value) {
    this.menuShow = value;
    this.setSize();
  }
  get showMenu() {
    return this.menuShow;
  }

  constructor(private _loggerService: LoggerService){ }

  ngOnInit(): void {
      this._loggerService.logger.logDebug('start: ngOnInit of home-global-featureComponent');

      if (document.location.href.indexOf('disabled=true') >= 0) {
        this.enabled = false;
      } else {
        this.enabled = true;
      }
      this._loggerService.logger.logDebug('end: ngOnInit of home-global-featureComponent');
  }

  ngAfterViewInit(): void {
      amc.initializeComplete(this._loggerService.logger).then((config: any) => {
        this._loggerService.logger.logDebug(`Configuration: ${JSON.stringify(config)}`);

        this.setSize();

        this._loggerService.logger.logInformation('initialize complete finished.', ERROR_CODE.APP_INITIALIZED);
      }).catch(error => this._loggerService.logger.logError('Problem with configuration! ' + error, ERROR_CODE.INITIALIZE_COMPLETE_ERROR));
  }

  /**
   * Sets height/width of app
   */
  setSize() {
    const height = this.showMenu ? this.maxHeight: this.minHeight;
    const width = this.showMenu ? this.maxWidth : this.mindWidth;

    amc.setAppHeight(height)
    .then(
      () => this._loggerService.logger.logInformation('setAppHeight Promise returned.')
    ).catch(
      () => this._loggerService.logger.logError('setAppHeight Promise rejected.')
    );

    amc.setAppWidth(width)
    .then(
      () => this._loggerService.logger.logInformation('setAppWidth Promise returned.')
    ).catch(
      () => this._loggerService.logger.logError('setAppWidth Promise rejected.')
    );

  }
}
