import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home-global-feature.component';
import { ToolbarControlComponent } from './toolbar-control/toolbar-control.component';
import { ScreenpopControlComponent } from './screenpop-control/screenpop-control.component';
import { ConfigurationService } from './services/configuration-global-feature.service';
import { LoggerService } from './services/logger-global-feature.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ToolbarControlComponent,
    ScreenpopControlComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
    ]),
    BrowserAnimationsModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule
  ],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: (configService: ConfigurationService, loggerService: LoggerService
    ) =>
      async () => {
        await configService.loadConfigurationData();
        await loggerService.initialize();
      },
    deps: [ConfigurationService, LoggerService],
    multi: true
  },
    LoggerService],
  bootstrap: [AppComponent]
})
export class AppModule { }
