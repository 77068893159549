import { Injectable } from '@angular/core';
import { Logger, LOG_SOURCE } from '@amc-technology/davinci-api';
import { ConfigurationService } from './configuration-global-feature.service';
@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  public logger: Logger;

  constructor(private configService: ConfigurationService) { }

  async initialize() {
    const serverConfig = await this.configService.loadConfigurationData();

    // useDevLogger is determined by an environmental variable in launch.json
    // Under "env", look for ClientConfiguration:UseDevLogger
    const useDevLogger = (this.configService.config.useDevLogger != null) &&
    this.configService.config.useDevLogger.toLowerCase() === 'true';
    this.logger = new Logger(
      LOG_SOURCE.GlobalControlApp,
      useDevLogger,
      serverConfig.loggerApiUrl
    );
  }
}
