import { Component, OnInit, enableProdMode } from '@angular/core';
import * as amc from '@amc-technology/davinci-api';

@Component({
  selector: 'app-screenpop-control',
  templateUrl: './screenpop-control.component.html',
  styleUrls: ['./screenpop-control.component.scss']
})
export class ScreenpopControlComponent implements OnInit {
  enable: boolean;

  set enabled(value) {
    this.enable = value;
    amc.setScreenpopControl(this.enable);
  }

  get enabled() {
    return this.enable;
  }

  constructor() { }

  ngOnInit(): void {
    this.enabled = true;
  }

}
