import { Component, Input, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import * as amc from '@amc-technology/davinci-api';
import { LoggerService } from '../services/logger-global-feature.service';
@Component({
  selector: 'app-toolbar-control',
  templateUrl: './toolbar-control.component.html',
  styleUrls: ['./toolbar-control.component.scss']
})
export class ToolbarControlComponent implements OnInit {
  enabled: boolean;

  @Input('enabled') set inputEnabled(value) {
    this.enabled = value;
  }

  constructor() { }

  ngOnInit(): void {
    this.enabled = true;

    if (document.location.href.indexOf('disabled=true') >= 0) {
      this.enabled = false;
    }
  }

  toggleChanged(value: MatSlideToggleChange) {
    this.enabled = value.checked;
    amc.setToolbarEnabled(value.checked);
  }
}
